/* PhotosInAlbum.css */

.photos-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 15px;
  padding: 20px;
  justify-items: center;
}

.photo-item {
  width: 150px;
  height: 150px;
  background-color: #f0f0f0;
  border: 2px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
}

.photo-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s;
}

.photo-item:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
}

.photo-item:after {
  content: '';
  display: block;
  padding-bottom: 100%;
}

.photo-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 30px;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 0.9rem;
  text-align: center;
  line-height: 30px;
  opacity: 0;
  transition: opacity 0.3s;
}

.photo-item:hover .photo-overlay {
  opacity: 1;
}

.photo-lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.photo-lightbox img {
  max-width: 90%;
  max-height: 80%;
  border-radius: 10px;
}

.lightbox-controls {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
}

.lightbox-arrow {
  font-size: 2rem;
  color: #fff;
  cursor: pointer;
  transition: transform 0.2s;
}

.lightbox-arrow:hover {
  transform: scale(1.2);
}

.lightbox-close {
  position: absolute;
  top: -15px;
  right: 10px;
  font-size: 2rem;
  color: #fff;
  cursor: pointer;
  transition: transform 0.2s;
}

.lightbox-close:hover {
  transform: scale(1.2);
}
.photo-lightbox-content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1001;
}

.lightbox-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
