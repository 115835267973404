.gallery-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}
.gallery-container > div {
  width: 100%;
}
.gallery-container h3 {
  text-align: center;
  color: #c0392b;
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.gallery-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.gallery-pagination button {
  margin: 0 5px;
  padding: 10px 15px;
  background-color: #ddd;
  color: #000;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.gallery-pagination button:hover {
  background-color: #c0392b;
  color: #fff;
}

.gallery-pagination button.active {
  background-color: #c0392b;
  color: #fff;
}

.gallery-pagination button.arrow {
  margin: 0 10px;
  background-color: transparent;
  color: #c0392b;
  font-size: 18px;
  padding: 10px;
}

.gallery-pagination button.arrow:hover {
  background-color: #f0f0f0;
}

.gallery-pagination button:focus {
  outline: none;
}

.gallery-lightbox {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* CSS styling for the gallery component */
.goin-container {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.gallery-fieldset {
  border: 2px solid #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1);
  max-width: 500px;
  width: 100%;
  margin: auto;
}

.gallery-legend {
  font-size: 1.2em;
  color: #fff;
  font-weight: bold;
  padding: 0 10px;
}

.gallery-input {
  width: calc(100%);
  padding: 10px;
  margin: 15px 0;
  border: 1px solid #ced4da;
  border-radius: 5px;
  font-size: 1em;
  transition: border-color 0.3s ease-in-out;
}

.gallery-input:focus {
  border-color: #c0392b;
  outline: none;
}

.gallery-submit {
  width: 100%;
  padding: 10px;
  background-color: #c0392b;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.gallery-submit:hover {
  background-color: #8c2b20;
}

.gallery-submit:active {
  transform: translateY(2px);
}

.gallery-container .back-button {
  background-color: #c0392b;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}
.album-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  gap: 20px;
}
