:root {
  --bg-color: #212121;
  --action-red-color: #ff0000;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--bg-color);
}

.App {
  padding-bottom: 100px;
}

.App section {
  margin-bottom: 80px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
header {
  display: block;
  position: relative;
  overflow: hidden;
}
header .background {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.2;
  z-index: -1;
  background: url('../public/background.jpg') no-repeat center center;
  background-size: cover;
}
.site-logo {
  display: block;
  text-align: center;
  max-width: 250px;
  width: 80%;
  margin: 100px auto;
}
.site-logo img {
  width: 100%;
}

.desktop-menu {
  display: block;
  text-align: center;
  max-width: 300px;
  width: 80%;
  margin: 100px auto;
  color: white;
}

@media only screen and (max-width: 576px) {
  .desktop-menu {
    display: none;
  }
}

.desktop-menu nav {
  height: auto;
  border-bottom: 2px solid gray;
  border-top: 2px solid gray;
  overflow: hidden;
  padding: 20px 0;
}

.desktop-menu nav a {
  text-decoration: none;
  color: gray;
  width: 100%;
  height: 100%;
  position: relative;
  display: inline;
  margin: 20px;
  font-size: 1.1rem;
  font-weight: 600;
}

.desktop-menu nav a:hover {
  color: white;
}
