/* Albums.css */

.albums-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding: 20px;
}

.album-item {
  width: 150px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #8b0000, #b22222);
  border: 2px solid #e0e0e0;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  transition: transform 0.3s, box-shadow 0.3s;
}

.album-item:before {
  content: '';
  position: absolute;
  top: -10px;
  left: 10px;
  width: 130px;
  height: 20px;
  background: linear-gradient(to bottom, #aa4a4a 0%, #8b3a3a 100%);
  border-radius: 10px 10px 0 0;
}

.album-item:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.25);
}

.album-name {
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
}
