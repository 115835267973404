article {
  border: 1px solid #ddd;
  padding: 50px 20px;
  background-color: #f9f9f9;
  max-width: 800px;
  margin: 20px auto;
  border-radius: 15px;
}

.article-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.publiced-on {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #e8e8e8;
  padding: 10px 15px;
  border-radius: 15px;
}

.publiced-on .day {
  font-size: 24px;
  font-weight: bold;
}

.publiced-on p {
  margin: 0;
  font-size: 12px;
}

.article-details {
  flex-grow: 1;
  margin-left: 20px;
}

.article-details h3 {
  margin: 0;
  font-size: 20px;
  margin-bottom: 5px;
}

.article-details p {
  font-size: 14px;
  color: #555;
  margin-top: 0;
  margin-bottom: 0;
}
.article-details span {
  font-weight: 500;
}

.article-content {
  margin-top: 20px;
}

.article-content p {
  font-size: 16px;
  line-height: 1.6;
  color: #333;
  margin-bottom: 10px;
}
