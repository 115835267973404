/* CSS styling for the calendar component */
.calendar-container {
  border: 1px solid #ddd;
  padding: 50px 20px;
  background-color: #f9f9f9;
  max-width: 800px;
  margin: 20px auto;
  border-radius: 15px;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.calendar-header h2 {
  font-size: 1.5em;
  color: #333;
}

.calendar-header button {
  background-color: #c0392b;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.calendar-header button:hover {
  background-color: #c0392b;
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
}

.calendar-day {
  padding: 10px;
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.calendar-day.event {
  background-color: #ffeb3b;
  font-weight: bold;
}

.calendar-day.today {
  background-color: #c0392b;
  color: #ffffff;
  font-weight: bold;
}

.calendar-day:hover {
  background-color: #e2e6ea;
}

.calendar-day.empty {
  visibility: hidden;
}

.event-details {
  margin-top: 20px;
  padding: 15px;
  border: 1px solid #6c757d;
  border-radius: 5px;
  background-color: #f8f9fa;
}

.event-details h3 {
  margin-top: 0;
}

.event-details button {
  margin-top: 10px;
  background-color: #c0392b;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.event-details button:hover {
  background-color: #c0392b;
}
