.login-page {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  /* background: linear-gradient(to right, #6a11cb, #2575fc); */
  top: 23px;
  right: 80px;
  position: absolute;
}

.login-button {
  width: 100%;
  padding: 7px 20px;
  background-color: #c0392b;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.login-button:hover {
  background-color: #8c2b20;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}

.popup-inner {
  background: #ffffff;
  padding: 30px;
  border-radius: 15px;
  text-align: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  width: 300px;
}

.input-field {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.submit-button {
  width: 100%;
  padding: 7px 20px;
  background-color: #c0392b;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  border: 1px solid #c0392b;
  margin-bottom: 5px;
}

.submit-button:hover {
  background-color: #8c2b20;
  border: 1px solid #8c2b20;
}

.error {
  color: #8c2b20;
  margin-top: 10px;
}

.close-button {
  width: 100%;
  padding: 7px 20px;
  background-color: #f3f3f3;
  color: #333;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  border: 1px solid #8c2b20;
}

.close-button:hover {
  background-color: #f9f9f9;
}
