/* Stylování burger ikony */
.burger-icon {
  cursor: pointer;
  width: 30px;
  height: 22px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  top: 30px;
  right: 30px;
  z-index: 1001;
}

.line {
  width: 100%;
  height: 3px;
  background-color: white;
  transition: all 0.3s ease;
}

.burger-icon.open .line:nth-child(1) {
  transform: translateY(9px) rotate(45deg);
}

.burger-icon.open .line:nth-child(2) {
  opacity: 0;
}

.burger-icon.open .line:nth-child(3) {
  transform: translateY(-9px) rotate(-45deg);
}

/* Stylování navigačního menu */
.nav-menu {
  position: fixed;
  top: 0;
  right: -100%; /* Skryté mimo obrazovku */
  width: 250px;
  height: 100%;
  background-color: black;
  /* box-shadow: -2px 0 5px rgb(200, 0, 0); */
  transition: right 0.3s ease;
  z-index: 1000;
  padding-top: 60px;
}

.nav-menu.active {
  right: 0; /* Vyjede z pravé strany */
}

.nav-menu ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  justify-content: center;
}

.nav-menu li {
  padding: 0;
  text-align: center;
  border-bottom: 1px solid white;
  width: 60%;
}

.nav-menu li:last-child {
  border-bottom: none;
}

.nav-menu li a {
  text-decoration: none;
  color: white;
  text-decoration: none;
  color: white;
  font-size: 18px;
  width: 100%;
  height: 100%;
  position: relative;
  display: inline-block;
  padding: 30px 0;
  font-size: 1.1rem;
  font-weight: 600;
}

.nav-menu li a:hover {
  color: var(--action-red-color);
}

/* Překryvný div pro kliknutí mimo */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 999;
  filter: blur(3px);
}
